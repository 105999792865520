export abstract class Patterns {
  static readonly HCN = '^[0-9]{4}-?[0-9]{3}-?[0-9]{3}$';
  static readonly MRN = '^[0-9A-Z]*$';
  static readonly TEXT = "^[0-9a-zA-ZÀ-ÿ àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ'‘’.,:;#\\-]*$";
  static readonly POSTAL_CODE = '^([a-zA-Z][0-9][a-zA-Z][ ][0-9][a-zA-Z][0-9])?$';
  static readonly DATE = '^(([0-9]{4}|[0-9]{2})-([0]?[1-9]|[1][0-2])-([0]?[1-9]|[1|2][0-9]|[3][0|1]))?$';
  static readonly TIME_24H = '^(([0-1][0-9]|[2][0-3]):([0-5][0-9]))?$';
  static readonly DATE_TEXT =
    '^[0-3]?\\d{1}\\s{1}[adfjmnosAFDJMNOS]{1}[abceghil-pr-vyABCEGHIL-PR-VY.éû]{2,8}\\s{1}[1-2]{1}\\d{3}$';
  static readonly BOOLEAN = '^(true|false)?$';
  static readonly FLOAT = '^([+-]?([0-9]{0,15}([.][0-9]{1,17})?)?)?$';
  static readonly SEX = '^(M|F|male|female)?$';
  static readonly PROV = '^[A-Z]{2}$';
  static readonly FACILITY_OID = '^[0-9.]+$';
  static readonly ALPHANUMERIC = '^[a-zA-Z0-9]+$';
}
