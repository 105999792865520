import { SharedServerRoutePath } from './shared-server-route-path';

/**
 * Keep values as individual routes to build routes within shared libraries.
 */
export abstract class SharedServerRouteURI {
  // Root path for API routes
  static readonly ApiRoute = {
    Root: `/${SharedServerRoutePath.ApiRoute.Root}`,
  };

  static readonly WebService = {
    Root: `/${SharedServerRoutePath.WebService.Root}`,
  };

  static readonly Auth = `/${SharedServerRoutePath.Auth}`;
  static readonly Auth_TTL = `/${SharedServerRoutePath.Auth_TTL}`;

  static readonly Nomenclature = {
    Root: `/${SharedServerRoutePath.Nomenclature.Root}`,
    MetaOLISTestRequest: `/${SharedServerRoutePath.Nomenclature.MetaOLISTestRequest}`,
    MetaOLISTestResult: `/${SharedServerRoutePath.Nomenclature.MetaOLISTestResult}`,
    MetaOLISTestMicro: `/${SharedServerRoutePath.Nomenclature.MetaOLISTestMicro}`,
    MetaPhysician: `/${SharedServerRoutePath.Nomenclature.MetaPhysician}`,
    MetaTestFacility: `/${SharedServerRoutePath.Nomenclature.MetaTestFacility}`,
    MetaLab: `/${SharedServerRoutePath.Nomenclature.MetaLab}`,
    MetaHospitalFacility: `/${SharedServerRoutePath.Nomenclature.MetaHospitalFacility}`,
    MetaPCRMRNRequestEMPI: `/${SharedServerRoutePath.Nomenclature.MetaPCRMRNRequestEMPI}`,
    MetaPCRHCNRequestEMPI: `/${SharedServerRoutePath.Nomenclature.MetaPCRHCNRequestEMPI}`,
    SearchMetaPCRMRNRequestEMPI: `/${SharedServerRoutePath.Nomenclature.SearchMetaPCRMRNRequestEMPI}`,
    SearchMetaOlisMRNRequestEMPI: `/${SharedServerRoutePath.Nomenclature.SearchMetaOlisMRNRequestEMPI}`,
  };

  static readonly ONEID = {
    Root: `/${SharedServerRoutePath.ONEID.Root}`,
    Login: `/${SharedServerRoutePath.ONEID.Login}`,
    Redirect: `/${SharedServerRoutePath.ONEID.Redirect}`,
    Logout: `/${SharedServerRoutePath.ONEID.Logout}`,
    LogoutCallback: `/${SharedServerRoutePath.ONEID.Logout}/${SharedServerRoutePath.ONEID.LogoutCallback}`,
    SSOLogin: `/${SharedServerRoutePath.ONEID.SSOLogin}`,
    SSOLogout: `/${SharedServerRoutePath.ONEID.SSOLogout}`,
  };

  static readonly CMS = {
    Root: `/${SharedServerRoutePath.CMS.Root}`,
    CurrentContext: `/${SharedServerRoutePath.CMS.CurrentContext}`,
    CurrentContextPatient: `/${SharedServerRoutePath.CMS.CurrentContextPatient}`,
    Language: `/${SharedServerRoutePath.CMS.Language}`,
    ConsentTarget: `/${SharedServerRoutePath.CMS.ConsentTarget}`,
    PatientClose: `/${SharedServerRoutePath.CMS.PatientClose}`,
    ValidateHubTopic: `/${SharedServerRoutePath.CMS.ValidateHubTopic}`,
    PatientOpen: `/${SharedServerRoutePath.CMS.PatientOpen}`,
  };

  static readonly PCR = {
    Root: `/${SharedServerRoutePath.PCR.Root}`,
    Search: `/${SharedServerRoutePath.PCR.Search}`,
  };

  static readonly Terms = {
    Root: `/${SharedServerRoutePath.Terms.Root}`,
    Accept: `/${SharedServerRoutePath.Terms.Accept}`,
    Accepted: `/${SharedServerRoutePath.Terms.Accepted}`,
  };

  static readonly Error = `/${SharedServerRoutePath.Error}`;

  static readonly OLIS = {
    Root: `/${SharedServerRoutePath.OLIS.Root}`,
    PatientSearch: `/${SharedServerRoutePath.OLIS.PatientSearch}`,
  };

  static readonly Preference = `/${SharedServerRoutePath.Preference}`;
}
