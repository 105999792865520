import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAuthResponse,
  ICommonRuntimeEnvironment,
  IUAO,
  IUAOWithSelected,
  SharedServerRouteURI,
  REQUEST_HEADER,
  IResult,
  REDIS_TTL,
} from '@one-access/shared/api';
import { SharedConstant } from '@one-access/shared/util';
import { BehaviorSubject, Observable, map, first, Subscription, retry, switchMap, timer } from 'rxjs';
import { IAuthService } from './auth.interface';
import { IRuntimeEnvironmentService } from '../runtime-environment';
import { BusyHttpInterceptor, HttpErrorInterceptor } from '../../interceptors';

/**
 * Authentication service for front-end applications to communicate and retrieve status of the user from the backend
 * server application.
 */
@Injectable()
export class AuthService implements IAuthService {
  private _defaultStatus: IAuthResponse = {
    sessionId: '',
    isAuthenticated: false,
    provider: '',
    currentUAO: '',
    uaos: [],
    hasPCRSearchEntitlement: false,
    hasOLISSearchEntitlement: false,
    givenName: '',
    familyName: '',
    cookieMaxAgeMSec: SharedConstant.DEFAULT_COOKIE_MAX_AGE_MSEC,
  };
  public lastProvider = '';
  private _env: ICommonRuntimeEnvironment;

  private _statusSubject = new BehaviorSubject<IAuthResponse>(this._defaultStatus);
  public status$ = this._statusSubject.asObservable();

  private _authTTLSubject: BehaviorSubject<number>;
  public authTTL$: Observable<number>;
  private authTTLSub?: Subscription;

  private _uaosWithSelectedSubject = new BehaviorSubject<IUAOWithSelected[]>([]);
  public uaosWithSelected$ = this._uaosWithSelectedSubject.asObservable();

  private _currentUAOObjSubject = new BehaviorSubject<IUAO | undefined>(undefined);
  public currentUAOObj$ = this._currentUAOObjSubject.asObservable();

  private _reminderTimerSubject: BehaviorSubject<{ min: number; sec: number }>;
  public reminderTimer$: Observable<{ min: number; sec: number }>;

  private _uaoSwitchInProgress = false;

  constructor(private http: HttpClient, runtimeEnv: IRuntimeEnvironmentService) {
    this._env = runtimeEnv.environment<ICommonRuntimeEnvironment>();
    this._reminderTimerSubject = new BehaviorSubject({
      min: Math.floor(this._env.reminderStartsAtMSec / 1000 / 60) + 1,
      sec: (this._env.reminderStartsAtMSec / 1000) % 60,
    });
    this.reminderTimer$ = this._reminderTimerSubject.asObservable();
    this._authTTLSubject = new BehaviorSubject(Math.floor(this._env.reminderStartsAtMSec / 1000) + 60);
    this.authTTL$ = this._authTTLSubject.asObservable();
  }

  public get status(): IAuthResponse {
    return this._statusSubject.getValue();
  }

  public get authTTL(): number {
    return this._authTTLSubject.getValue();
  }

  public get currentUAOObj(): IUAO | undefined {
    return this._currentUAOObjSubject.getValue();
  }

  public get uaosWithSelected(): IUAOWithSelected[] {
    return this._uaosWithSelectedSubject.getValue();
  }

  public extendSession(): void {
    this.cancelAuthTTL();
    this.auth().subscribe(() => {
      this.getAuthTTL(this.status.sessionId);
    });
  }

  public setReminderTimer(min: number, sec: number): void {
    this._reminderTimerSubject.next({ min: min, sec: sec });
  }
  public getReminderTimer(): { min: number; sec: number } {
    return this._reminderTimerSubject.value;
  }

  /**
   * Set the value to true when session expired message is not supposed to popup due to UAO selection. ONE ID callback process resets the value back to false.
   */
  public set UAOSwitchInProgress(status: boolean) {
    this._uaoSwitchInProgress = status;
  }
  public get UAOSwitchInProgress(): boolean {
    return this._uaoSwitchInProgress;
  }

  public auth(): Observable<IAuthResponse> {
    const endPoint = `${this._env.apiUrl}${SharedServerRouteURI.Auth}`;
    const httpContext = new HttpContext();
    httpContext.set(HttpErrorInterceptor.CONTEXT_TOKEN_SKIP, true);
    httpContext.set(BusyHttpInterceptor.CONTEXT_TOKEN_SKIP, true);
    return this.http
      .get(endPoint, {
        withCredentials: true,
        observe: 'response',
        context: httpContext,
      })
      .pipe(
        first(),
        map((res) => {
          const authStatus = <IAuthResponse>res.body ?? this._defaultStatus;
          if (authStatus.isAuthenticated || !this._uaoSwitchInProgress) {
            this._statusSubject.next(authStatus);
            const uaosWithSelectedArr = this.status.uaos?.map((uao) => {
              const uaoWithSelected: IUAOWithSelected = {
                type: uao.type,
                id: uao.id,
                friendName: uao.friendName,
                service: uao.service,
                selected: uao.id === this.status.currentUAO,
              };
              return uaoWithSelected;
            });
            this._uaosWithSelectedSubject.next(uaosWithSelectedArr ?? []);
            const currentUAOObjArr = this.uaosWithSelected.filter((uao) => uao.selected);
            this._currentUAOObjSubject.next(currentUAOObjArr[0]);

            if (this.status.isAuthenticated) {
              this.lastProvider = this.status.provider;
            }
          }

          return this.status;
        })
      );
  }

  public getAuthTTL(sessionId: string) {
    if (!this.authTTLSub || this.authTTLSub.closed) {
      const endPoint = `${this._env.apiUrl}${SharedServerRouteURI.Auth_TTL}`;
      const headers = new HttpHeaders();
      headers.append(REQUEST_HEADER.ContentType, 'application/json');
      const httpContext = new HttpContext();
      httpContext.set(HttpErrorInterceptor.CONTEXT_TOKEN_SKIP, true);
      httpContext.set(BusyHttpInterceptor.CONTEXT_TOKEN_SKIP, true);
      const queryParams = {
        sessionID: sessionId,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      const requestUrl = `${endPoint}?${queryString}`;
      const resp = this.http.get(requestUrl, {
        withCredentials: true,
        observe: 'response',
        headers,
        context: httpContext,
      });
      this.authTTLSub = timer(0, this._env.reminderRefreshIntervalMSec)
        .pipe(
          switchMap(() =>
            resp.pipe(
              retry({ delay: this._env.reminderRefreshIntervalMSec }),
              map((x) => {
                const dataResp = <IResult<number>>x.body;
                this._authTTLSubject.next(dataResp.data ?? REDIS_TTL.FOREVER);
                if (dataResp.data === REDIS_TTL.EXPIRED && !this._uaoSwitchInProgress) {
                  const currStatus = this.status;
                  currStatus.isAuthenticated = false;
                  this._statusSubject.next(currStatus);
                }
                return dataResp.data ?? REDIS_TTL.FOREVER;
              })
            )
          )
        )
        .subscribe();
    }
    return;
  }

  public cancelAuthTTL() {
    this.authTTLSub?.unsubscribe();
  }
}
