import { IErrorType } from './error-type.interface';
import { IErrorTypes } from './error-types.interface';

export const ErrorTypes: IErrorTypes = {
  Auth: {
    AuthenticationError: <IErrorType>{
      id: 1000,
      type: 'Auth',
      message: 'Authentication error occurred.',
    },
    AuthenticationResponseError: <IErrorType>{
      id: 1001,
      type: 'Auth',
      message: 'Authentication error occurred.',
    },
    RefreshTokenError: <IErrorType>{
      id: 1002,
      type: 'Auth',
      message: 'Internal error occurred.',
    },
    InvalidScopeError: <IErrorType>{
      id: 1003,
      type: 'Auth',
      message: 'Invalid scopes, unauthorized.',
    },
  },
  CMS: {
    GetCurrentContextError: {
      id: 2000,
      type: 'CMS',
      message: 'Unknown error occurred.',
    },
    PatientOpenError: {
      id: 2001,
      type: 'CMS',
      message: 'Internal error occurred.',
    },
    PatientCloseError: {
      id: 2002,
      type: 'CMS',
      message: 'Unknown error occurred.',
    },
    CreateHubTopicError: {
      id: 2003,
      type: 'CMS',
      message: 'Unknown error occurred.',
    },
    UserLoginError: {
      id: 2004,
      type: 'CMS',
      message: 'Unknown error occurred.',
    },
    UserLogoutError: {
      id: 2005,
      type: 'CMS',
      message: 'Unknown error occurred.',
    },
    OrganizationChangeError: {
      id: 2006,
      type: 'CMS',
      message: 'Unknown error occurred.',
    },
    HubTopicIdRequiredError: {
      id: 2007,
      type: 'CMS',
      message: 'Internal error occurred.',
    },
  },
  PCR: {
    GeneralError: <IErrorType>{
      id: 3000,
      type: 'PCR',
      message: 'Unknown error occurred.',
    },
    MatchRequestError: <IErrorType>{
      id: 3001,
      type: 'PCR',
      message: 'Error occurred during patient search.',
    },
  },
  OLIS: {
    GeneralError: {
      id: 4000,
      type: 'OLIS',
      message: 'Unknown error occurred.',
    },
    PatientSearchError: {
      id: 4001,
      type: 'OLIS',
      message: 'Patient search error occurred.',
    },
    PatientSearchResponseError: {
      id: 4002,
      type: 'OLIS',
      message: 'Patient search error occurred.',
    },
  },
  Redis: {
    NotConnected: {
      id: 5000,
      type: 'REDIS',
      message: 'Unable to connect.',
    },
    ReconnectExceeded: {
      id: 5001,
      type: 'REDIS',
      message: 'Failed to connect after multiple attempts.',
    },
    FullTextSearchError: {
      id: 5002,
      type: 'REDIS',
      message: 'Failed to retrieve data.',
    },
  },
  General: {
    Unknown: {
      id: 9000,
      type: 'ERROR',
      message: 'Unknown error occurred.',
    },
    Validation: {
      id: 9005,
      type: 'VALIDATION',
      message: 'Validation error occurred. Please check your data and try again.',
    },
  },
  UserPreference: {
    CreateError: {
      id: 9001,
      type: 'USERPREFERENCE',
      message: 'Error while creating a new user preference.',
    },
    UpdateError: {
      id: 9002,
      type: 'USERPREFERENCE',
      message: 'Error occurred while updating user preference.',
    },
    ClearError: {
      id: 9003,
      type: 'USERPREFERENCE',
      message: 'Error occurred while clearing the user preference.',
    },
    GetError: {
      id: 9004,
      type: 'USERPREFERENCE',
      message: 'Error occurred while getting the user preference.',
    },
  },
  AppCatalogue: {
    GetContentError: {
      id: 9020,
      type: 'APPCATALOGUE',
      message: 'Unknown error occurred.',
    },
    GetEntitledAppsError: {
      id: 9021,
      type: 'APPCATALOGUE',
      message: 'Unknown error occurred.',
    },
  },
  Terms: {
    GetContentError: {
      id: 9040,
      type: 'TERMS',
      message: 'Unknown error occurred.',
    },
    GetApplicableTermsError: {
      id: 9041,
      type: 'TERMS',
      message: 'Unknown error occurred.',
    },
  },
  Http: {
    BadRequest: {
      id: 400,
      type: 'HTTP',
      message: 'Invalid request.',
    },
    Unauthorized: {
      id: 401,
      type: 'HTTP',
      message: 'Unauthorized.',
    },
    NotFound: {
      id: 404,
      type: 'HTTP',
      message: 'Not found.',
    },
    Error: {
      id: 500,
      type: 'HTTP',
      message: 'Unknown error occurred.',
    },
  },
};
